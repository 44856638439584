span.nw {
  white-space: nowrap;
}

#scrollbar_container {
  position: fixed;
  right: 0;
  top: 0;
  width: 5px;
  height: 100dvh;
}

#scrollbar_slider {
  position: fixed;
  right: 0;
  width: 5px;
  background-color: #333333;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: transform 0.05s;
  will-change: transform;
}

#title_container {
  position: fixed;
  font-weight: normal;
  text-align: right;
  bottom: 65px;
  right: 28px;
  padding-left: 20lvh;
  text-shadow: 1px 1px 1px #00000080;
}

#title {
  font-weight: normal;
  font-size: 64px;
  margin-bottom: 0.125em;
}

#button_read_more {
  margin-top: 0.5em;
  text-decoration: underline;
}

#description_bg_svg {
  position: fixed;
  bottom: -10px;
  right: -10px;
  width: 450px;
  height: 280px;
}

#description_buttons_svg {
  position: fixed;
  bottom: -10px;
  right: -10px;
  width: 450px;
  height: 280px;
}

#description_container {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 440px;
  height: 270px;
  pointer-events: none;
}

#description_shape_left {
  width: 173px;
  height: 100%;
  float: left;
  shape-outside: polygon(0 0, 100% 0, 15% 100%, 0 100%);
  pointer-events: none;
}

#description_shape_right {
  width: 135px;
  height: 100%;
  float: right;
  shape-outside: polygon(100% 100%, 100% 75%, 29% 75%, 0 100%);
  pointer-events: none;
}

#description_title {
  position: relative;
  transform: translateX(-10px);
  font-size: 32px;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  padding-right: 14px;
  pointer-events: auto;
}

#description_text_container {
  position: fixed;
  font-weight: normal;
  bottom: 0px;
  right: 0px;
  width: 383px;
  height: 256px;
  pointer-events: none;
  clip-path: polygon(100% 100%, 100% 0, 38% 0, 0 100%);
}

#description_text {
  position: relative;
  padding-right: 14px;
  pointer-events: auto;
}

#description_text_detail {
  font-size: 12px;
  margin-top: 10px;
}

#description_text_price {
  font-size: 20px;
  margin-top: 10px;
}

#description_text_price_detail {
  display: inline;
  font-size: 12px;
  margin-left: 9px;
}

#thumbnail_title {
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  margin: 0;
  transform: translate(71px, -71px) rotate(-45deg);
}

#menu_svg {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 360px;
  height: 360px;
  filter: drop-shadow(-1px 2px 1px #00000080);
}

#menu_bg_svg {
  position: fixed;
  top: -10px;
  left: -10px;
  width: 420px;
  height: 420px;
}

#logo_svg {
  position: fixed;
  top: -10px;
  right: -10px;
  width: 320.25px;
  height: auto;
  filter: drop-shadow(-1px 2px 1px #00000080);
}

#checkout_panel_svg {
  position: fixed;
  top: -10px;
  right: -10px;
  width: 320.25px;
  height: auto;
}

#checkout_panel {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: safe center;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100lvh;
  background-color: #000000cc;
  backdrop-filter: blur(4px);
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overscroll-behavior: none;
}

#checkout_panel_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  min-width: 336px;
  width: 60%;
  max-width: 600px;
  min-height: 52px;
  max-height: 52px;
}

#checkout_panel_row_margin {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  min-width: 336px;
  width: 60%;
  max-width: 600px;
  min-height: 96px;
  min-height: 96px;
}

#checkout_panel_row_spacer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  min-width: 336px;
  width: 60%;
  max-width: 600px;
  min-height: 24px;
  min-height: 24px;
}

#checkout_panel_row_shipping {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  min-width: 336px;
  width: 60%;
  max-width: 600px;
  min-height: 48px;
  min-height: 48px;
}

#checkout_panel_row_final_total {
  display: flex;
  align-items: flex-end;
  justify-content: right;
  min-width: 336px;
  width: 60%;
  max-width: 600px;
  min-height: 52px;
  max-height: 52px;
}

#checkout_panel_cell_final_total {
  font-size: 32px;
  margin-bottom: 6px;
}

#checkout_panel_cell_final_total_usd {
  display:inline;
  font-size: 12px;
  margin-right: 24px;
  margin-bottom: 6px;
}

#checkout_panel_column_paypal {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  margin-top: 24px;
  min-width: 336px;
  width: 60%;
  max-width: 600px;
  min-height: 90px;
  max-height: 90px;
}

#checkout_panel_cell_type {
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
  width: 96px;
  max-width: 180px;
  flex-grow: 1;
  flex-shrink: 0;
}

#checkout_panel_cell_price {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  max-width: 96px;
  flex-grow: 1;
  flex-shrink: 0;
}

#checkout_panel_cell_count {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96px;
  max-width: 128px;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 32px;
}

#checkout_panel_cell_count_title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  max-width: 128px;
  flex-grow: 1;
  flex-shrink: 0;
}

#checkout_panel_cell_total {
  display: flex;
  align-items: center;
  justify-content: right;
  text-align: right;
  width: 64px;
  max-width: 96px;
  flex-grow: 1;
  flex-shrink: 0;
}

#checkout_panel_cell_country {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  max-width: 240px;
  flex-grow: 1;
  flex-shrink: 0;
}

#checkout_panel_cell_shipping_time {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 160px;
  max-width: 240px;
  flex-grow: 1;
  flex-shrink: 0;
}

#checkout_panel_cell_paypal_description {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  min-width: 200px;
  width: 200px;
  max-width: 200px;
  min-height: 20px;
  max-height: 20px;
}

#checkout_panel_cell_paypal_error {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  min-width: 200px;
  width: 200px;
  max-width: 200px;
  flex-grow: 1;
  flex-shrink: 0;
}

#checkout_panel_country_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #00000066;
  border-top: 2px solid #333333;
  border-bottom: 2px solid #333333;
  border-radius: 7px;
  box-shadow: 2px 2px 5px 0 rgba(0,0,0,.5);
  min-height: 32px;
  max-height: 32px;
  user-select: none;
}

#checkout_panel_country_overlay {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 30vh;
  background-color: #00000066;
  backdrop-filter: blur(4px);
  z-index: 1;
  border-top: 2px solid #333333;
  border-bottom: 2px solid #333333;
  border-radius: 7px;
  box-shadow: 2px 2px 5px 0 rgba(0,0,0,.5);
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overscroll-behavior: none;
  user-select: none;
}

#checkout_panel_country_overlay_item {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
}

#checkout_panel_plus_svg, #checkout_panel_minus_svg {
  display: flex;
  width: 36px;
  height: 36px;
}

#checkout_panel_success_title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 64px;
  min-width: 336px;
  width: 60%;
  max-width: 600px;
  min-height: 52px;
  max-height: 52px;
}

#checkout_panel_success {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  min-width: 336px;
  width: 60%;
  max-width: 600px;
}

#checkout_panel_success > a {
  color: #FFFFFF;
  text-decoration: none;
  display: contents;
}

#checkout_panel_success_h1 {
  font-weight: normal;
  font-size: 64px;
  margin-top: 0;
  margin-bottom: 0.25em;
}

#checkout_panel_success_h2 {
  font-weight: normal;
  font-size: 32px;
  margin-bottom: 1em;
}

footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
}

#contact_container {
  position: fixed;
  bottom: -266px;
  left: -5.77px;
  width: 527.77px;
  height: 308px;
  pointer-events: none;
}

#contact_svg {
  filter: drop-shadow(-1px 2px 1px #00000080);
}

#contact_title {
  position: absolute;
  top: 16px;
  left: 71px;
  text-align: left;
  user-select: none;
  pointer-events: none;
}

#contact_details {
  position: absolute;
  top: 54px;
  left: 26px;
  text-align: left;
}

#contact_text_title {
  position: relative;
  margin-bottom: 16px;
  font-size: 20px;
  user-select: text;
  pointer-events: auto;
}

#contact_text_medium, #contact_text_medium > a {
  position: relative;
  color: #FFFFFF;
  margin-bottom: 16px;
  font-size: 16px;
  text-decoration: none;
  user-select: text;
  pointer-events: auto;
}

#contact_text, #contact_text > a {
  position: relative;
  color: #FFFFFF;
  font-size: 12px;
  text-decoration: none;
  user-select: text;
  pointer-events: auto;
}

#contact_text_detail {
  position: relative;
  font-size: 10px;
  user-select: text;
  pointer-events: auto;
}

#copyright_container {
  position: fixed;
  bottom: -10px;
  left: 122px;
  width: 119.31px;
  height: 36px;
  pointer-events: none;
}

#copyright_title {
  position: absolute;
  top: 12px;
  left: 20px;
  font-size: 9px;
  text-align: left;
  user-select: text;
  pointer-events: auto;
}

#scroll_button_svg {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 120px;
  height: 64px;
}

#detail_container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100lvh;
}

#detail_media_top, #detail_media_bottom {
  position: fixed;
  width: 100%;
  height: 100lvh;
  object-fit: cover;
  object-position: 50% 50%;
}

#detail_controls_left_svg {
  position: fixed;
  bottom: calc(50vh - 24px);
  left: 0px;
  width: 48px;
  height: 48px;
}

#detail_controls_right_svg {
  position: fixed;
  bottom: calc(50vh - 24px);
  right: 0px;
  width: 48px;
  height: 48px;
}

#detail_play_button_svg {
  position: fixed;
  bottom: calc(50vh - 48px);
  left: calc(50vw - 48px);
  width: 96px;
  height: 96px;
}

#detail_controls_items_container {
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
  bottom: 8px;
  right: calc(50vw - 27px);
  width: 232px;
  height: 20px;
  pointer-events: none;
}

#detail_widget {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

#detail_widget_content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 192px;
}

#detail_widget_svg {
  position: absolute;
  width: 480px;
  height: 192px;
}

#detail_widget_text {
  position: absolute;
  display: flex;
  width: inherit;
  height: inherit;
  align-items: center;
  justify-content: center;
}

#detail_widget_text_title {
  position: absolute;
  width: calc(100% - 44px);
  top: 5px;
  left: 45px;
  text-align: center;
  font-size: 20px;
  user-select: none;
  pointer-events: none;
}

#detail_widget_button_container {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(100% - 38px);
  height: 32px;
  top: 148px;
  left: 0px;
}

#detail_widget_button {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 32px;
  background-color: #00000066;
  border-top: 2px solid #333333;
  border-bottom: 2px solid #333333;
  border-radius: 7px;
  pointer-events: auto;
}

#detail_widget_text_button {
  display: flex;
  user-select: none;
}

#detail_widget_text_external_url {
  display: flex;
  width: inherit;
  height: fit-content;
  margin-left: 30px;
  margin-right: 30px;
  text-align: center;
  justify-content: center;
  font-size: 12px;
  pointer-events: auto;
}

#detail_widget_quantity_container {
  display: flex;
  width: inherit;
  gap: 32px;
  justify-content: center;
}

#detail_widget_color_container {
  display: flex;
  width: inherit;
  gap: 48px;
  justify-content: center;
}

#detail_widget_text_cart_amount {
  min-width: 50px;
  text-align: center;
  font-size: 32px;
  pointer-events: none;
}

#detail_widget_plus_svg,
#detail_widget_minus_svg,
#detail_widget_color_field_svg {
  width: 36px;
  height: 36px;
}

#detail_widget_checkmark {
  filter: drop-shadow(-1px 2px 1px #00000080);
}

/*#detail_preload_container #detail_preload_media {
  visibility: hidden;
  width: 0px;
  height: 0px;
}*/

#link_home:hover,
#link_digital:hover,
#link_games:hover,
#link_design:hover,
#link_store:hover,
#link_contact:hover,
#menu_button_main:hover,
#logo_link:hover,
#scroll_button_link:hover,
#contact_button:hover,
#thumbnail_link_area:hover,
#button_read_more:hover,
#detail_controls_left_button:hover,
#detail_controls_right_button:hover,
#detail_controls_item:hover,
#detail_widget_button:hover,
#detail_widget_close_bg:hover,
#detail_widget_minus_bg:hover,
#detail_widget_plus_bg:hover,
#detail_widget_color_field_bg:hover,
#checkout_panel_link:hover,
#checkout_panel_plus_bg:hover,
#checkout_panel_minus_bg:hover,
#description_button_first:hover,
#description_button_second:hover,
#description_button_third:hover,
#checkout_panel_cell_country:hover,
#media_container_content > video:hover,
#detail_container_content > video:hover {
  cursor: pointer;
}

#media_container_content, #detail_container_content {
  position: fixed;
  display: inline-block;
}

#media_container_content > img, #detail_container_content > img, #media_container_content > video, #detail_container_content > video {
  display: block;
}

#media_container_content::after, #detail_container_content::after {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100lvh;
  box-shadow:
    inset 5px 5px 150px #000000aa,
    inset -5px -5px 150px #000000aa;
  pointer-events: none;
}

#media {
  position: fixed;
  width: 100vw;
  height: 100lvh;
  object-fit: cover;
  object-position: 50% 50%;
}

#media_loader_container {
  position: fixed;
  width: 100vw;
  height: 100lvh;
  background-color: #000000;
}

#main_container {
  position: absolute;
  width: 100vw;
  height: 100lvh;
}

#main_container_content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding-top: 100lvh;
  padding-bottom: 100lvh;
  clip-path: url(#fade_path);
  background-color: #000000cc;
}

#fade_svg {
  position: fixed;
  top: 0;
  pointer-events: none;
}

#fade_path {
  position: fixed;
  top: 0;
  pointer-events: none;
}

#items_container {
  position: relative;
  width: 100%;
}

#items_section {
  position: relative;
  width: 100%;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  flex-direction: row;
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

#items_section::-webkit-scrollbar {
  display: none;
}

#items_container_content {
  max-width: min-content;
  display: flex;
  flex-direction: row;
  gap: 32px;
  padding-left: 50vw;
  padding-right: 50vw;
  padding-top: 16px;
  padding-bottom: 16px;
}

#items_controls_left_svg {
  position: absolute;
  padding-top: 120px;
  top: 0px;
  left: 0px;
  width: 48px;
  height: 48px;
}

#items_controls_right_svg {
  position: absolute;
  padding-top: 120px;
  top: 0px;
  right: 0px;
  width: 48px;
  height: 48px;
}

#thumbnail_container {
  width: 256px;
  height: 256px;
  flex: none;
  user-select: none;
  filter: drop-shadow(3px 5px 2px #00000080);
}

#thumbnail_link_svg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

#thumbnail_image {
  clip-path: path('M85.9,0c-6.6,0-14.7,4.677-18,10.392L0,128v128h115.997c6.6,0,15.818-3.818,20.485-8.485L256,128V0H85.9z');
  user-select: none;
  pointer-events: none;
}

#thumbnail_title {
  user-select: none;
  pointer-events: none;
}

#text_container {
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1300px) and (hover: none) and (orientation: portrait) {

  p {
    font-size: 20px;
    margin-left: 10%;
    margin-right: 10%;
  }

  h2 {
    font-size: 32px;
    margin-left: 10%;
    margin-right: 10%;
  }

  #menu_svg {
    width: 100vw;
    height: auto;
  }

  #menu_bg_svg {    
    top: calc(-10 / 360 * 100vw);
    left: calc(-10 / 360 * 100vw);
    width: calc(420 / 360 * 100vw);
    height: auto;
  }

  #title_container {
    bottom: calc(65 / 360 * 100vw);
    right: calc(28 / 360 * 100vw);
  }

  #title_text {
    font-size: 20px;
  }

  #button_read_more {
    font-size: 20px;
  }

  #logo_svg {
    top: calc(-10 / 360 * 100vw);
    right: calc(-10 / 360 * 100vw);
    width: calc(252 / 360 * 100vw);
    height: auto;
  }

  #checkout_panel_svg {
    top: calc(-10 / 360 * 100vw);
    right: calc(-10 / 360 * 100vw);
    width: calc(252 / 360 * 100vw);
    height: auto;
  }

  #contact_container {
    bottom: calc(-269 / 360 * 100vw);
    left: calc(-5.77 / 360 * 100vw);
    width: calc(527.77 / 360 * 100vw);
    height: auto;
    pointer-events: none;
  }

  #contact_details {
    position: absolute;
    top: calc(54 / 360 * 100vw);
    left: calc(26 / 360 * 100vw);
    text-align: left;
  }

  #contact_title {
    top: calc(18 / 360 * 100vw);
    left: calc(71 / 360 * 100vw);
    font-size: calc(14 / 360 * 100vw);
  }

  #contact_text {
    font-size: calc(10 / 360 * 100vw);
  }
  
  #contact_text_title {
    margin-bottom: calc(16 / 360 * 100vw);
    font-size: calc(20 / 360 * 100vw);
  }

  #contact_text_medium {
    margin-bottom: calc(16 / 360 * 100vw);
    font-size: calc(16 / 360 * 100vw);
  }
  
  #contact_text_detail {
    font-size: calc(10 / 360 * 100vw);
  }

  #copyright_container {
    bottom: calc(-10 / 360 * 100vw);
    left: calc(122 / 360 * 100vw);
    width: calc(119.31 / 360 * 100vw);
    height: auto;
  }

  #copyright_title {
    top: calc(15 / 360 * 100vw);
    left: calc(21 / 360 * 100vw);
    font-size: calc(9 / 360 * 100vw);
  }

  #button_scroll_svg {
    width: calc(120 / 360 * 100vw);
    height: auto;
  }

  #detail_controls_left_svg {
    bottom: calc(50vh - (24 / 360 * 100vw));
    left: 0px;
    width: calc(48 / 360 * 100vw);
    height: calc(48 / 360 * 100vw);
  }
  
  #detail_controls_right_svg {
    bottom: calc(50vh - (24 / 360 * 100vw));
    right: 0px;
    width: calc(48 / 360 * 100vw);
    height: calc(48 / 360 * 100vw);
  }

  #detail_controls_items_container {
    bottom: 264px;
    right: 0px;
  }

  #items_controls_left_svg {
    padding-top: calc(144px - (24 / 360 * 100vw));
    width: calc(48 / 360 * 100vw);
    height: calc(48 / 360 * 100vw);
  }
  
  #items_controls_right_svg {
    padding-top: calc(144px - (24 / 360 * 100vw));
    width: calc(48 / 360 * 100vw);
    height: calc(48 / 360 * 100vw);
  }

}

@media (max-width: 800px) {

  #detail_controls_items_container {
    bottom: 264px;
    right: 0px;
  }

}

@media (max-height: 560px) {
  
  #detail_controls_right_svg {
    right: calc(440px - 29vh);
  }

}